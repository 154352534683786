import RevealOnScroll from "./RevealOnScroll";
import RevealOnScrollOnce from "./RevealOnScrollOnce";

import CoreTeamMember from "./CoreTeamMember";

const Team = () => {
  return (
    <section
      className="flex flex-col items-center justify-start bg-white
    pt-[140px] px-[100px] pb-[80px] box-border self-stretch
    max-w-full text-center text-25xl text-stroke-stroke-06 font-plus-jakarta-sans 
    lg:pb-[52px] lg:box-border mq450:gap-[20px] mq750:gap-[40px] mq750:pb-[34px] mq750:box-border
    mq1024:mt-0 mq1024:mx-0 mq1024:px-[16px] mq1024:pb-[15px] mq1024:mb-[0px] mq1024:pt-[80px]
    mq1024:w-full mq1024:h-full"
      data-scroll-to="OurTeam"
    >
      <div className="flex flex-col items-center justify-start gap-[24px] max-w-full">
        <RevealOnScroll
          className="hidden_text relative text-inherit tracking-[-0.02em] leading-[57px] font-medium font-inherit 
        w-[518px] h-auto m-0
        inline-block max-w-full 
        mq450:text-7xl mq450:leading-[34px] mq1050:text-16xl mq1050:leading-[46px]
        mq1024:w-full"
        >
          Leadership Team
        </RevealOnScroll>
        <RevealOnScroll
          className="hidden_text relative 
        w-[870px] h-auto text-lg tracking-[0.5px] leading-[180%] text-text-body-copy inline-block
        mq1024:w-full"
        >
          The development of AlpinX is backed by the underlying relationships,
          compliance, legal structure and financial expertise of our core team.
          Their combined experience provides a secure foundation for us to be
          the custodian of digital assets and provider of services.
        </RevealOnScroll>
      </div>

      <div
        className="
      pt-[80px] flex flex-col items-center justify-center gap-[60px] self-stretch flex-wrap
      mq1024:flex-wrap mq1024:gap-[10px]
      "
      >
        <RevealOnScrollOnce
          className="hidden_text flex flex-row justify-around gap-[30px] self-stretch flex-wrap
        mq1024:gap-[10px]

        "
        >
          <CoreTeamMember
            role="Chief Executive Officer"
            photo="RA.png"
            name="Richard Adams"
            linkedin="rgaatalpinx"
            propColor="#5353e6"
          />

          <CoreTeamMember
            role="Chief Scientist - AI"
            photo="CD.png"
            name="Chari Dingari, Ph.D"
            linkedin="ncdingari"
            propColor="#5353e6"
          />

          <CoreTeamMember
            role="Chief Technology Officer"
            photo="DG.png"
            name="Dmytro Gyedz, CS"
            linkedin="dmytro-gyedz"
            propColor="#5353e6"
          />
        </RevealOnScrollOnce>

        <RevealOnScrollOnce
          className="hidden_text flex flex-row justify-around gap-[30px] self-stretch flex-wrap 
         mq1024:gap-[10px]
        "
        >
          <CoreTeamMember
            role="Chief Marketing Officer"
            photo="MS.png"
            name="Mariana Shatova, MBA"
            linkedin="marianashatova"
            propColor="#5353e6"
          />

          <CoreTeamMember
            role="Chief Product Designer"
            photo="IR.png"
            name="Igor Reznichenko"
            linkedin="igorreznichenko"
            propColor="#5353e6"
          />

          <CoreTeamMember
            role="Business Development"
            photo="VG.png"
            name="Vika Gryshakova, MBA"
            linkedin="vikagrishakova"
            propColor="#5353e6"
          />
        </RevealOnScrollOnce>
      </div>
    </section>
  );
};

export default Team;
