import { useMemo } from "react";

const Roadmap_Text = ({
  alpinXUtilityTokenGoLive,
  stateOfTheArtMostUserFrie,
  q32024,
  propBackgroundColor,
}) => {
  const roadmapItemStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  return (
    <div className="w-[410px] flex flex-col items-start justify-start gap-[32px] max-w-full text-left text-lg text-stroke-stroke-02 font-plus-jakarta-sans mq450:gap-[16px]
    mq768:w-[350px] mq768:text-[16px]">
      <div className="self-stretch relative tracking-[0.5px] leading-[160%] inline-block">
        <ul className="m-0 font-inherit text-inherit pl-6">
          <li className="mb-3">{alpinXUtilityTokenGoLive}</li>
          <li>{stateOfTheArtMostUserFrie}</li>
        </ul>
      </div>
      <button
        className="cursor-pointer [border:none] py-3 px-[12px] bg-mediumseagreen-100 rounded-21xl flex flex-row items-center justify-start whitespace-nowrap hover:bg-mediumseagreen-200"
        style={roadmapItemStyle}
      >
        <div className="h-5 w-[135px] px-0 relative text-xl tracking-[-0.02em] leading-[100%] uppercase font-semibold font-plus-jakarta-sans text-surface-01 text-left inline-block
                      items-center justify-center [text-align:center]">
          {q32024}
        </div>
      </button>
    </div>
  );
};

export default Roadmap_Text;
